import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { cn } from '../../lib/helpers';
import BlockText from '../block-text';

import styles from './project-preview.module.css';
import { responsiveTitle3 } from '../../styles/typography.module.css';
import { ConditionalWrapper } from '../../utils/tools';

function ProjectPreview(props) {
  // console.log('ProjectPreview props: ', props);
  const {
    school,
    slug,
    title,
    isExternal,
    externalLink,
    _rawExcerpt,
    linkOverride,
    masonry,
    media,
    small,
    heroImage,
    onClick,
  } = props;
  let displayFeaturedMedia = {};
  if (heroImage && heroImage[0] && heroImage[0].image) {
    displayFeaturedMedia = heroImage[0];
  } else if (media) {
    displayFeaturedMedia = media[0];
  }

  const preventDefault = (event) => {
    event.preventDefault();
  };

  return (
    <ConditionalWrapper
      condition={school && !(linkOverride && linkOverride !== '')}
      wrapper={(children) =>
        /* if project links to external site, then open in new tab */
        isExternal ? (
          <a
            className={cn(styles.root, small && styles.small, !masonry && styles.stdGrid)}
            href={externalLink}
            target={`_blank`}
            onClick={(e) => {
              if (onClick) {
                preventDefault(e);
                onClick();
              }
            }}
          >
            {children}
          </a>
        ) : (
          <Link
            className={cn(styles.root, small && styles.small, !masonry && styles.stdGrid)}
            to={`/schools/${school.slug.current}/projects/${slug.current}`}
            onClick={(e) => {
              if (onClick) {
                preventDefault(e);
                onClick();
              }
            }}
          >
            {children}
          </Link>
        )
      }
    >
      <ConditionalWrapper
        condition={linkOverride && linkOverride !== ''}
        wrapper={(children) => (
          <Link
            className={cn(styles.root, small && styles.small, !masonry && styles.stdGrid)}
            to={linkOverride}
            onClick={(e) => {
              if (onClick) {
                preventDefault(e);
                onClick();
              }
            }}
          >
            {children}
          </Link>
        )}
      >
        <div className={masonry ? cn(styles.leadMediaThumb, styles.leadMediaThumbMasonry) : styles.leadMediaThumb}>
          {displayFeaturedMedia && displayFeaturedMedia.image && (
            <Img fluid={displayFeaturedMedia.image.asset.fluid} alt={displayFeaturedMedia.alt} />
          )}
          <h3 className={cn(responsiveTitle3, styles.title)}>
            {title}
            {isExternal ? <FontAwesomeIcon style={{ marginLeft: '.5em' }} icon={faExternalLinkAlt} /> : ''}
          </h3>
          {_rawExcerpt && (
            <div className={styles.excerpt}>
              <BlockText blocks={_rawExcerpt} />
            </div>
          )}
        </div>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}

export default ProjectPreview;
